// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const host = {
  authServer: 'https://idp-dev.unicloudgroup.com.vn/realms/umee-finance-dev',
  apiKSFServer: 'https://dev-api-umeeinvest.unicloudgroup.com.vn',
  apiCoreServer: 'https://dev-api-umeeconfig.unicloudgroup.com.vn',
  apiUploadS3: 'https://strapi-staging.umeeapp.co',
};

const authenSettings = {
  authority: host.authServer,
  client_id: 'umee-finance-frontend-home-plus-client',
  redirect_uri: 'https://dev-web-premier.umee.com.vn/auth-callback',
  post_logout_redirect_uri: 'https://dev-web-premier.umee.com.vn',
  response_type: 'code',
  scope: 'openid profile',
  filterProtocolClaims: true,
  loadUserInfo: true,
  automaticSilentRenew: true,
  silent_redirect_uri: 'https://dev-web-premier.umee.com.vn/silent-refresh.html'
};

export const environment = {
  production: true,
  apiKSFBase: host.apiKSFServer,
  apiCoreBase: host.apiCoreServer,
  apiUploadS3Base: host.apiUploadS3,
  authBase: host.authServer + '/connect/token',
  authenSettings: authenSettings,
};



// const host = {
//   authServer: 'https://idp-staging.umeeapp.co/auth/realms/umee-finance',
//   apiKSFServer: 'https://api-staging.umeeapp.co/invest',
//   apiCoreServer: 'https://api-staging.umeeapp.co/config',
//   apiUploadS3: 'https://strapi-staging.umeeapp.co',
// };

// const authenSettings = {
//   authority: host.authServer,
//   client_id: 'umee-finance-frontend-home-plus-client',
//   redirect_uri: 'https://home-plus-staging.umeeapp.co/auth-callback',
//   post_logout_redirect_uri: 'https://invest-staging.umeeapp.co',
//   response_type: 'code',
//   scope: 'openid profile',
//   filterProtocolClaims: true,
//   loadUserInfo: true,
//   automaticSilentRenew: true,
//   silent_redirect_uri: 'https://home-plus-staging.umeeapp.co/silent-refresh.html'
// };

// export const environment = {
//   production: true,
//   apiKSFBase: host.apiKSFServer,
//   apiCoreBase: host.apiCoreServer,
//   apiUploadS3Base: host.apiUploadS3,
//   authBase: host.authServer + '/connect/token',
//   authenSettings: authenSettings,
// };
